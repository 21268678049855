import { sendToaster, ToasterState } from '../stores';
import { SetterOrUpdater } from 'recoil';
import { ErrorMessage } from '../types/error-message-types';

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export const toastErrorFromApiCatch = (
  error: ErrorMessage,
  setRecoil: SetterOrUpdater<ToasterState>,
) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return sendToaster(error.response.data.message, 'error', setRecoil);
  } else {
    sendToaster(error.message, 'error', setRecoil);
  }
};
