import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { Toaster } from './Toaster/Toaster';
import { useAuth0 } from '@auth0/auth0-react';
import { loadWhoiam, userStore } from './stores';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { UserWhoiamDto } from './api';
import { loadingStore } from './stores';
import logoGsi from './Header/logo.png';
import {
  BurgerMenuProvider,
  DefaultLayout,
  SpinningLoader,
} from '@vallourec-ui/vallourec-ui';
// import { useTranslation } from 'react-i18next';
// import { DashboardHeader } from './Header';
import { goto } from './helpers';
import { LoginPage } from './shared/LoginPage/LoginPage';

export const appName = 'GSI V2';

const App = () => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
    logout,
    loginWithPopup,
  } = useAuth0();

  const dashboardUser: UserWhoiamDto | null = useRecoilValue(userStore);
  const setRecoilDashboardUser: SetterOrUpdater<UserWhoiamDto | null> =
    useSetRecoilState(userStore);

  const loading = useRecoilValue(loadingStore);

  // const { t } = useTranslation();

  const username =
    user?.name != null && user?.family_name
      ? user.name.concat(user.family_name)
      : '';

  const usermail = user?.email != null ? user.email : '';

  useEffect(() => {
    if (isAuthenticated && user && user.email) {
      loadWhoiam(getAccessTokenSilently, setRecoilDashboardUser);
    }
  }, [isAuthenticated]);

  if (!isLoading && dashboardUser) {
    return (
      <>
        <BurgerMenuProvider>
          <DefaultLayout
            appName={appName}
            userName={username}
            pathName={'Liste des applications'}
            userOptions={[
              {
                key: 'deconnection',
                label: 'Déconnexion',
                onClick() {
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                },
              },
            ]}
            menuSetttings={{
              itemGroups: [
                {
                  key: 'apps',
                  label: 'Applications',
                  roles: ['test'],
                  items: [
                    {
                      key: 'appList',
                      icon: 'HomeLine',
                      label: 'Liste des applications',
                      onClick() {
                        goto('/');
                      },
                    },
                    {
                      key: 'addApp',
                      icon: 'EditLine',
                      label: 'Ajouter une application',
                      onClick() {
                        goto('/application/create');
                      },
                    },
                  ],
                },
                {
                  key: 'env',
                  label: usermail,
                  roles: ['test'],
                  items: [
                    {
                      key: 'int',
                      icon: 'StackLine',
                      label: 'Intégration',
                      onClick() {
                        window.open('https://dashboard.app.int.vallourec.com/');
                      },
                    },
                    {
                      key: 'qual',
                      icon: 'StackLine',
                      label: 'Qualification',
                      onClick() {
                        window.open(
                          'https://dashboard.app.qual.vallourec.com/',
                        );
                      },
                    },
                    {
                      key: 'prod',
                      icon: 'StackLine',
                      label: 'Production',
                      onClick() {
                        window.open('https://dashboard.app.vallourec.com/');
                      },
                    },
                  ],
                },
              ],
            }}
            navigate={function (path: string): void {
              console.log(path);
            }}
          >
            <div className={'content'}>
              {loading ? (
                <div className="loader">
                  <SpinningLoader />
                </div>
              ) : (
                ''
              )}
              <Toaster />
              <RouterProvider router={router} />
            </div>
          </DefaultLayout>
        </BurgerMenuProvider>
      </>
    );
  } else {
    return (
      <>
        <LoginPage
          appName={appName}
          loginFunction={loginWithPopup}
          logo={logoGsi}
        ></LoginPage>
      </>
    );
  }
};
export default App;

//
