import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { RecoilRoot } from 'recoil';
import { gsi2Configuration } from './config';
import {
  LanguageSelectorProvider,
  VallourecThemeProvider,
} from '@vallourec-ui/vallourec-ui';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './i18n/default/en.json';
import fr from './i18n/default/fr.json';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: ['en', 'fr'],
    whitelist: ['en', 'fr'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      lookupLocalStorage: 'language',
    },
  });

root.render(
  <React.StrictMode>
    <VallourecThemeProvider>
      <LanguageSelectorProvider>
        <RecoilRoot>
          <Auth0Provider
            // useRefreshTokens={true}
            domain={gsi2Configuration?.domain as string}
            clientId={gsi2Configuration?.clientId as string}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: gsi2Configuration?.audience,
              scope: gsi2Configuration?.scope,
            }}
          >
            <App />
          </Auth0Provider>
        </RecoilRoot>
      </LanguageSelectorProvider>
    </VallourecThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
