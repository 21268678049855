import React from 'react';
import './DataTable.css';
import { Input, Paper, TableContainer } from '@mui/material';
import {
  applicationStore,
  paginationStore,
  PaginationStoreModel,
  searchStore,
} from '../../stores';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { goto } from '../../helpers';
import { ApplicationModel } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, NewTable, Tooltip } from '@vallourec-ui/vallourec-ui';
import { useTranslation } from 'react-i18next';

export interface DataTableActions<T> {
  label: string;
  onclick: (item: T) => void;
  showStatementCondition?: (item: T) => boolean;
}

export interface DataTableRendere<T> {
  fieldName: string;
  renderer: (item: T) => string | React.ReactNode;
}

export interface DataTableProps<T> {
  data: T[];
  renderers?: DataTableRendere<T>[];
  actions: DataTableActions<T>[];
  columnsToShow?: string[];
  addOnclick?: () => void;
  hasPagination?: boolean;
  searchFieldHandler?: CallableFunction;
}

export function DataTable<T>(props: DataTableProps<T>) {
  const { t } = useTranslation();
  const addLabel = t('Add') || 'Add';

  const { getAccessTokenSilently } = useAuth0();
  const currentPagination: PaginationStoreModel =
    useRecoilValue(paginationStore);

  const application: ApplicationModel | null = useRecoilValue(applicationStore);

  const setQ: SetterOrUpdater<string> = useSetRecoilState(searchStore);

  const data: T[] = props.data;
  const columnsToShow = props.columnsToShow;
  const actions: DataTableActions<T>[] = props.actions;

  let tableContent = <></>;

  const search = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!application) return;
    if (!getAccessTokenSilently) return;
    if (!props.searchFieldHandler) return;
    if (!event.target.value) return;
    if (event.target.value.length < 2) {
      setQ('');
      return goto(`/application/${application.id}/users/1`);
    } else {
      goto(
        `/application/${application.id}/users/${encodeURI(
          event.target.value,
        )}/${currentPagination.page}`,
      );
    }
  };

  if (data.length === 0 || data[0] === undefined || !columnsToShow) {
    tableContent = <></>;
  } else {
    const headers = columnsToShow.map(item => ({
      accessorKey: item,
      header: t(`columns.${item}`),
      cell: ({ cell }) => {
        if (
          props.renderers !== undefined &&
          props.renderers.find(
            (renderer: DataTableRendere<T>) => renderer.fieldName === item,
          )
        ) {
          const renderer = props.renderers.find(
            (renderer: DataTableRendere<T>) => renderer.fieldName === item,
          );
          if (renderer !== undefined) {
            return <span>{renderer?.renderer(cell.row.original)}</span>;
          }
        } else {
          return (
            <Tooltip side="left" label={cell.getValue()}>
              <span>{cell.getValue()}</span>
            </Tooltip>
          );
        }
      },
    }));

    const actionColumns =
      actions &&
      actions.map((action: DataTableActions<T>) => ({
        accessorKey: action.label,
        header: t(`columns.${action.label}`),
        cell: ({ cell }) => (
          <Button
            value={cell.row}
            onClick={() => {
              if (cell.row.original) {
                action.onclick(cell.row.original);
              }
            }}
          >
            {action.label}
          </Button>
        ),
      }));

    const allHeaders = headers.concat(actionColumns);

    tableContent = (
      <TableContainer
        component={Paper}
        style={{ marginTop: 10 }}
        className="DataTable"
      >
        <NewTable.Root
          columns={allHeaders}
          data={data as object[]}
          hasPagination
        />
      </TableContainer>
    );
  }

  return (
    <>
      {props.addOnclick === undefined ? (
        <></>
      ) : (
        <Button
          onClick={() => {
            props.addOnclick?.();
          }}
          className="btn btn-primary"
        >
          {addLabel}
        </Button>
      )}
      {props.searchFieldHandler === undefined ? (
        <></>
      ) : (
        <Input
          placeholder={'Search'}
          style={{
            marginLeft: 10,
          }}
          onChange={search}
          className="btn btn-primary"
        />
      )}
      {tableContent}
    </>
  );
}
