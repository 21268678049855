import './ProxyPage.css';
import { ListingPage } from '../ListingPage';
import { ViewPage } from '../ViewPage';
import { FormPage } from '../FormPage';
import { useLoadEntitiesFromUrl } from '../../hooks/load-entities-from-url';
import React, { useEffect, useState } from 'react';
import { EntityTypes } from '../../types';
// import { capitalizeFirstLetter } from '../../helpers';
import { useRecoilValue } from 'recoil';
import { ApplicationModel } from '../../api';
import { applicationStore } from '../../stores';

export interface ProxyPageProps {
  title: string;
  entityType: EntityTypes;
  pageType: 'listing' | 'view' | 'form';
  formType?: 'create' | 'update' | 'delete';
}

export interface BreadcrumbItem {
  breadcrumb: string;
  path: string;
}

export const ProxyPage = (props: ProxyPageProps) => {
  useLoadEntitiesFromUrl(props.pageType, props.entityType, props.formType);
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const entityType: EntityTypes = props.entityType;
  const pageType: 'listing' | 'view' | 'form' = props.pageType;
  const formType: 'create' | 'update' | 'delete' | undefined = props.formType;

  const [pageName, setPageName] = useState<string>('');

  let output = <></>;

  if (pageType === 'form') {
    output = <FormPage entityType={entityType} formType={formType} />;
  } else if (pageType === 'view') {
    output = <ViewPage entityType={entityType} />;
  } else if (pageType === 'listing') {
    output = <ListingPage entityType={entityType} />;
  } else {
    output = <div>Unknown page type</div>;
  }

  const setBreadcrumb: CallableFunction = () => {
    if (application !== null && entityType != 'application') {
      // breadcrumbs.push({
      //   breadcrumb: `${application.name} - ${entityViewModel}`,
      //   path: `/application/${application.id}/${entityViewModel}`,
      // });

      // if (formType == 'create') {
      //   breadcrumbs.push({
      //     breadcrumb: 'create',
      //     path: ``,
      //   });
      // } else if (formType == 'update') {
      //   breadcrumbs.push({
      //     breadcrumb: 'edit',
      //     path: ``,
      //   });
      // }
      setPageName(application?.name);
    } else if (application !== null && entityType == 'application') {
      // if (formType == 'create') {
      //   // breadcrumbs.push({
      //   //   breadcrumb: 'create',
      //   //   path: ``,
      //   // });
      // } else if (formType == 'update') {
      //   // breadcrumbs.push({
      //   //   breadcrumb: 'edit',
      //   //   path: ``,
      //   // });
      // }
      setPageName(application?.name);
    } else {
      if (formType == 'create') {
        setPageName('New application');
      }
    }
    // setBreadcrumbItems(breadcrumbs);
  };

  useEffect(() => {
    setBreadcrumb();
  }, [window.location.pathname, application, location.pathname]);

  return (
    <div className={'proxy-page'}>
      <div className={'dashboard-breadcrumb'}>
        <p>{pageName}</p>
      </div>
      {output}
    </div>
  );
};
