import { plainToInstance } from 'class-transformer';
import { GSI2Configuration } from './gsi2-configuration.class';

export const intConfiguration: GSI2Configuration = plainToInstance(
  GSI2Configuration,
  {
    domain: 'int-vallourec.eu.auth0.com',
    clientId: 'seNXrBSe8ESDtG5peOo6xwsV9nl07Lyk',
    audience: 'https://api.auth.app.int.vallourec.com',
    scope: 'openid email profile',
    apiUrl: 'api.auth.app.int.vallourec.com',
    env: 'int',
  },
);
