import { qualConfiguration } from './qual';
import { intConfiguration } from './int';
import { prodConfiguration } from './prod';
import { GSI2Configuration } from './gsi2-configuration.class';
import { localConfiguration } from './local';

const env: string | undefined = process.env.REACT_APP_ENV;

const digitalPipeConfigurationMap = new Map<string, GSI2Configuration>();
digitalPipeConfigurationMap.set('int', intConfiguration);
digitalPipeConfigurationMap.set('qual', qualConfiguration);
digitalPipeConfigurationMap.set('prod', prodConfiguration);
digitalPipeConfigurationMap.set('local', localConfiguration);

export const gsi2Configuration: GSI2Configuration | undefined =
  digitalPipeConfigurationMap.get(env ?? 'local');
