import './PermissionsForm.css';

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  applicationStore,
  loadOnePermission,
  loadPermissions,
  PermissionFormModel,
  permissionFormStore,
  permissionsStore,
  resetPermissionFormStore,
  rolesStore,
  selectedPermissionsStore,
  selectedRoleStore,
  sendToaster,
  setPermissionFormStoreRoleId,
  ToasterState,
  toasterStore,
} from '../../stores';
import {
  ApplicationModel,
  createPermission,
  PermissionModel,
  removePermission,
  RoleModel,
  updatePermission,
} from '../../api';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { buttonStyle } from '../../styles';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { router } from '../../router';
import { getErrorMessage, goto, toastErrorFromApiCatch } from '../../helpers';
import { plainToInstance } from 'class-transformer';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(role: RoleModel, roles: RoleModel[], theme: Theme) {
  return {
    fontWeight:
      roles.findIndex((_role: RoleModel) => _role.id === role.id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export interface PermissionModelFormProps {
  mode: 'update' | 'delete' | 'create';
  applicationPermissionsMode?: boolean;
}

export const PermissionForm = (props: PermissionModelFormProps) => {
  const mode: 'update' | 'delete' | 'create' = props.mode;

  /*HOOKS*/
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const application: ApplicationModel | null = useRecoilValue(applicationStore);
  const setSelectedPermission: CallableFunction | null = useSetRecoilState(
    selectedPermissionsStore,
  );
  const permission: PermissionModel | null = useRecoilValue(
    selectedPermissionsStore,
  );
  const role: RoleModel | null = useRecoilValue(selectedRoleStore);
  const roles: RoleModel[] = useRecoilValue(rolesStore);
  const permissionFormValues: PermissionFormModel =
    useRecoilValue(permissionFormStore);
  const setRecoilPermission: SetterOrUpdater<PermissionModel | null> =
    useSetRecoilState(selectedPermissionsStore);
  const setRecoilPermissions: SetterOrUpdater<PermissionModel[]> =
    useSetRecoilState(permissionsStore);
  const setRecoilToaster: SetterOrUpdater<ToasterState> =
    useSetRecoilState(toasterStore);
  const setRecoilPermissionForm: SetterOrUpdater<PermissionFormModel> =
    useSetRecoilState(permissionFormStore);
  /*HOOKS*/

  /*HANDLERS*/
  const handleCreate = async e => {
    e.preventDefault();
    try {
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      if (!props.applicationPermissionsMode && !role) {
        sendToaster('Role is required', 'error', setRecoilToaster);
        return;
      }

      if (permissionFormValues.name.match(/^[a-z0-9:-]+$/g) === null) {
        sendToaster(
          'Role name name can only contain alphanumeric characters and - or :',
          'error',
          setRecoilToaster,
        );
        return;
      }

      await createPermission(
        getAccessTokenSilently,
        application.clientId,
        permissionFormValues.name,
        permissionFormValues.description,
        permissionFormValues.rolesIds,
      );
      await loadPermissions(
        getAccessTokenSilently,
        application,
        setRecoilPermissions,
      );
      setSelectedPermission(null);
      resetPermissionFormStore(setRecoilPermissionForm);
      sendToaster('Permission created', 'success', setRecoilToaster);
      if (props.applicationPermissionsMode) {
        goto(`/application/${application.id}/permissions`);
        return;
      } else if (role) {
        goto(`/application/${application.id}/role/${role.id}/permissions`);
        return;
      }
    } catch (e) {
      toastErrorFromApiCatch(
        {
          message: getErrorMessage(e),
          response: {
            data: {
              message: getErrorMessage(e),
            },
          },
        },
        setRecoilToaster,
      );
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    try {
      if (permission === null) {
        sendToaster('Permission not found', 'error', setRecoilToaster);
        return;
      }
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }

      if (permissionFormValues.name.match(/^[a-z0-9:-]+$/g) === null) {
        sendToaster(
          'Role name name can only contain alphanumeric characters and - or :',
          'error',
          setRecoilToaster,
        );
        return;
      }

      if (!props.applicationPermissionsMode && !role) {
        sendToaster('Role is required', 'error', setRecoilToaster);
        return;
      }
      await updatePermission(
        getAccessTokenSilently,
        application.clientId,
        permission.id,
        permissionFormValues.name,
        permissionFormValues.description,
        permissionFormValues.rolesIds,
      );
      await loadOnePermission(
        getAccessTokenSilently,
        application,
        setRecoilPermission,
        permission.id,
      );
      sendToaster('Permission updates', 'success', setRecoilToaster);
      resetPermissionFormStore(setRecoilPermissionForm);
      if (props.applicationPermissionsMode) {
        goto(`/application/${application.id}/permissions`);
        return;
      } else if (role) {
        goto(`/application/${application.id}/role/${role.id}/permissions`);
        return;
      }
    } catch (e) {
      toastErrorFromApiCatch(
        {
          message: getErrorMessage(e),
          response: {
            data: {
              message: getErrorMessage(e),
            },
          },
        },
        setRecoilToaster,
      );
    }
  };

  const handleDelete = async e => {
    e.preventDefault();
    try {
      if (permission === null) {
        sendToaster('Permission not found', 'error', setRecoilToaster);
        return;
      }
      if (!application) {
        sendToaster('Application not found', 'error', setRecoilToaster);
        return;
      }
      if (!props.applicationPermissionsMode && !role) {
        sendToaster('Role is required', 'error', setRecoilToaster);
        return;
      }
      await removePermission(
        getAccessTokenSilently,
        application.clientId,
        permission.id,
      );
      setSelectedPermission(null);
      await loadPermissions(
        getAccessTokenSilently,
        application,
        setRecoilPermissions,
      );
      sendToaster('Permission deleted', 'success', setRecoilToaster);
      resetPermissionFormStore(setRecoilPermissionForm);
      if (props.applicationPermissionsMode) {
        goto(`/application/${application.id}/permissions`);
        return;
      } else if (role) {
        goto(`/application/${application.id}/role/${role.id}/permissions`);
        return;
      }
    } catch (e) {
      toastErrorFromApiCatch(
        {
          message: getErrorMessage(e),
          response: {
            data: {
              message: getErrorMessage(e),
            },
          },
        },
        setRecoilToaster,
      );
    }
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setPermissionFormStoreRoleId(
      permissionFormValues,
      setRecoilPermissionForm,
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  /*HANDLERS*/

  if (!application) return <></>;

  if (mode === 'delete' && !permission) return <></>;
  if (mode === 'update' && !permission) return <></>;

  let button = <></>;

  if (mode === 'delete' && permission) {
    button = (
      <Button variant="contained" style={buttonStyle} onClick={handleDelete}>
        Delete
      </Button>
    );
  } else if (mode === 'update' && permission) {
    button = (
      <Button
        variant="contained"
        style={buttonStyle}
        disabled={
          permissionFormValues.nameError.length !== 0 ||
          permissionFormValues.descriptionError.length !== 0
        }
        onClick={handleUpdate}
      >
        Update
      </Button>
    );
  } else {
    button = (
      <Button
        variant="contained"
        style={buttonStyle}
        disabled={
          permissionFormValues.nameError.length !== 0 ||
          permissionFormValues.descriptionError.length !== 0
        }
        onClick={handleCreate}
      >
        Create
      </Button>
    );
  }

  if (!props.applicationPermissionsMode) {
    if (!role) return <></>;
  }

  return (
    <form method={'POST'}>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <TextField
            error={permissionFormValues.nameError.length > 0}
            helperText={permissionFormValues.nameError}
            disabled={mode === 'delete'}
            value={permissionFormValues.name}
            onChange={event => {
              event.preventDefault();
              let error = '';
              if (event.target.value.length < 3) {
                error = 'Name must be at least 3 characters long';
              }
              setRecoilPermissionForm(
                plainToInstance(PermissionFormModel, {
                  ...permissionFormValues,
                  name: event.target.value,
                  nameError: error,
                }),
              );
            }}
            id="standard-basic-name"
            label="Name"
            variant="standard"
          />
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <TextField
            error={permissionFormValues.descriptionError.length > 0}
            helperText={permissionFormValues.descriptionError}
            disabled={mode === 'delete'}
            value={permissionFormValues.description}
            onChange={event => {
              event.preventDefault();
              let error = '';
              if (
                event.target.value.trim().length > 0 &&
                event.target.value.trim().length < 6
              ) {
                error = 'Name must be at least 6 characters long';
              }
              setRecoilPermissionForm(
                plainToInstance(PermissionFormModel, {
                  ...permissionFormValues,
                  description: event.target.value,
                  descriptionError: error,
                }),
              );
            }}
            id="standard-basic-description"
            label="Description"
            variant="standard"
          />
        </FormControl>
      </div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">Roles</InputLabel>
        <Select
          disabled={props.mode === 'delete'}
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={permissionFormValues.rolesIds}
          onChange={handleChange}
          input={<OutlinedInput label="Roles" />}
          MenuProps={MenuProps}
        >
          {roles.map((role: RoleModel) => (
            <MenuItem
              key={role.id}
              value={role.id}
              style={getStyles(role, roles, theme)}
            >
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          {button}
          <br />
          <Button
            style={buttonStyle}
            onClick={() => {
              if (role && !props.applicationPermissionsMode) {
                router.navigate(
                  `/application/${application.id}/role/${role.id}/permissions`,
                );
              } else {
                router.navigate(`/application/${application.id}/permissions`);
              }
            }}
            variant="contained"
          >
            Cancel
          </Button>
        </FormControl>
      </div>
    </form>
  );
};

/*
* <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Role</InputLabel>
          <Select
            disabled={props.mode === 'delete'}
            labelId="demo-multiple-role-label"
            id="demo-multiple-role"
            value={roleFormValues.roleId}
            onChange={(event) => {
              event.preventDefault();
              setPermissionFormStoreRoleId(roleFormValues, setRecoilPermissionForm, parseInt(event.target.value.toString()));
            }}
            input={<OutlinedInput label="Role" />}
            MenuProps={MenuProps}
          >
            {roles.map((role: RoleModel) => (
              <MenuItem
                key={role.id}
                value={role.id}
                style={getStyles(role, roles, theme)}
              >
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
* */
