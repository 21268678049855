import { Button, Text } from '@vallourec-ui/vallourec-ui';
import React from 'react';
import './LoginPage.css';

export interface LoginPageProps {
  appName: string;
  loginFunction: () => void;
  logo: string;
}

export const LoginPage = (props: LoginPageProps) => {
  return (
    <div className="login-page">
      <div className="login-page-left-part">
        <div className="login-page-left-part-overlay"></div>
        <div className="login-page-left-part-logo">
          <img
            src={props.logo}
            style={{ cursor: 'pointer' }}
            className={'vallourec-logo'}
          />
        </div>
        <div className="login-page-left-part-punchline">
          <Text>Welcome to {props.appName}</Text>
        </div>
      </div>
      <div className="login-page-right-part">
        <div className="login-page-auth-part">
          <div className="login-page-auth-title">Sign in to</div>
          <div className="login-page-auth-logo">
            <Text>{props.appName}</Text>
          </div>
          <Button onClick={props.loginFunction}>
            Sign in with your account
          </Button>
        </div>
      </div>
    </div>
  );
};
