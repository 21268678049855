import { plainToInstance } from 'class-transformer';
import { GSI2Configuration } from './gsi2-configuration.class';

export const qualConfiguration: GSI2Configuration = plainToInstance(
  GSI2Configuration,
  {
    domain: 'qual-vallourec.eu.auth0.com',
    clientId: 'S7MU9SO2htAuZDS5tpf4mb1RiqESCqJb',
    audience: 'https://api.auth.app.qual.vallourec.com',
    scope: 'openid email profile',
    apiUrl: 'api.auth.app.qual.vallourec.com',
    env: 'qual',
  },
);
