import { plainToInstance } from 'class-transformer';
import { GSI2Configuration } from './gsi2-configuration.class';

export const prodConfiguration: GSI2Configuration = plainToInstance(
  GSI2Configuration,
  {
    domain: 'prod-vallourec.eu.auth0.com',
    clientId: 'CANCrCrPMXhpFVzCMQdQy6ySx6r62pV5',
    audience: 'https://api.auth.app.vallourec.com',
    scope: 'openid email profile',
    apiUrl: 'api.auth.app.vallourec.com',
    env: 'prod',
  },
);
